import React, { useState } from 'react'

import * as styles from './EventCardMenu.styles'
import LoadingSpinner from '../../LoadingSpinner'
import DeleteEventButton from './DeleteEventButton'
import { ReportEventButton } from './ReportEventButton'
import { EditEventButton } from './EditEventButton'
import { ScanTicketsButton } from './ScanTicketsButton'
import { EventOrdersButton } from './EventOrdersButton'
import { EventSummaryButton } from './EventSummaryButton'
import { Popover } from '../../Popover'
import { Button } from 'react-aria-components'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'
import { EventContextMenuItem } from '../../../graphql/types.gen'
import { useGetEventContextMenuQuery } from './getEventContextMenu.query.gen'

interface EventCardProps {
  event: EventDetailsFragment
}

const EventCardMenu: React.FC<EventCardProps> = ({ event }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { data, loading } = useGetEventContextMenuQuery({
    variables: {
      eventId: event.id,
    },
    skip: !showMenu,
    fetchPolicy: 'cache-first',
  })

  const contextMenuItems = data?.getEventContextMenu.ok

  const menuItems: React.ReactNode[] = []

  const renderMenu = () => {
    if (!showMenu || !contextMenuItems || contextMenuItems.length === 0) {
      return null
    }

    const defaultOnClick = () => setShowMenu(false)

    const menuItemComponents: Record<EventContextMenuItem, React.ReactNode> = {
      [EventContextMenuItem.Admission]: (
        <ScanTicketsButton event={event} style={styles.menuItemStyle} />
      ),
      [EventContextMenuItem.ViewOrders]: (
        <EventOrdersButton slug={event.slug} style={styles.menuItemStyle} />
      ),
      [EventContextMenuItem.ViewSummary]: (
        <EventSummaryButton event={event} style={styles.menuItemStyle} />
      ),
      [EventContextMenuItem.Edit]: (
        <EditEventButton event={event} style={styles.menuItemStyle} />
      ),
      [EventContextMenuItem.UntagSelectedProfile]: null, // untagging doesn't work on the back end yet
      [EventContextMenuItem.Delete]: (
        <DeleteEventButton
          event={event}
          onClick={defaultOnClick}
          style={styles.menuItemStyle}
        />
      ),
      [EventContextMenuItem.Report]: (
        <ReportEventButton event={event} style={styles.menuItemStyle} />
      ),
    }

    if (contextMenuItems) {
      for (const menuItem of contextMenuItems) {
        const itemComponent = menuItemComponents[menuItem]

        if (itemComponent) {
          menuItems.push(itemComponent)
        }
      }
    }

    return menuItems
  }

  return (
    <Popover
      isOpen={showMenu}
      onOpenChange={setShowMenu}
      button={
        loading ? (
          <LoadingSpinner />
        ) : (
          <Button
            css={styles.menuButton}
            data-testid="event-action-menu-button"
            onPress={() => setShowMenu(!showMenu)}
          >
            <i className="fas fa-ellipsis-h" />
          </Button>
        )
      }
    >
      {renderMenu()}
    </Popover>
  )
}

export default EventCardMenu
