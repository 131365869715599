import { Link } from '@remix-run/react'

import { createEventOrdersPath } from '../../../features/tickets/ticketRoutes'
import { primary } from '../../Button/Button.styles'

interface OrdersButtonProps {
  slug: string
  style?: Style
}

export const EventOrdersButton: React.FC<OrdersButtonProps> = ({
  slug,
  style = primary({ fullWidth: true }),
}) => {
  return (
    <Link css={style} to={createEventOrdersPath(slug)} tabIndex={0}>
      <i className="fa fa-inbox" />
      Orders
    </Link>
  )
}
